import { render, staticRenderFns } from "./NoteForm.vue?vue&type=template&id=6a0cab7c&scoped=true&"
import script from "./NoteForm.vue?vue&type=script&lang=js&"
export * from "./NoteForm.vue?vue&type=script&lang=js&"
import style0 from "./NoteForm.scss?vue&type=style&index=0&id=6a0cab7c&lang=scss&scoped=true&"
import style1 from "./NoteForm.css?vue&type=style&index=1&id=6a0cab7c&scoped=true&lang=css&"
import style2 from "./NoteForm.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0cab7c",
  null
  
)

export default component.exports